@font-face {
  font-family: "una-icon";
  src: url("../../public/fonts/una-icon.eot?q98pfg");
  src: url("../../public/fonts/una-icon.eot?q98pfg#iefix") format("embedded-opentype"),
    url("../../public/fonts/una-icon.ttf?q98pfg") format("truetype"),
    url("../../public/fonts/una-icon.woff?q98pfg") format("woff"),
    url("../../public/fonts/una-icon.svg?q98pfg#una-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "una-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-award-icon:before {
  content: "\e900";
  color: #d1bcb2;
}
.i-design-icon:before {
  content: "\e901";
  color: #d1bcb2;
}
.i-forniture-icon:before {
  content: "\e902";
  color: #d1bcb2;
}
.i-love-icon:before {
  content: "\e90c";
  color: #d1bcb2;
}
.i-man-icon:before {
  content: "\e911";
  color: #d1bcb2;
}
.i-price-icon:before {
  content: "\e91c";
  color: #d1bcb2;
}
.i-wood-icon:before {
  content: "\e921";
  color: #d1bcb2;
}
.i-brownstar-icon:before {
  content: "\e922";
  color: #d1bcb2;
}
.i-emptystar-icon:before {
  content: "\e903";
}
.i-burgermenu-icon:before {
  content: "\e904";
}
.i-cart-icon:before {
  content: "\e907";
  color: #ad8775;
}
.i-chevrondown-icon:before {
  content: "\e908";
  color: #ad8775;
}
.i-chevronleft-icon:before {
  content: "\e909";
  color: #ad8775;
}
.i-chevronright-icon:before {
  content: "\e90a";
  color: #ad8775;
}
.i-chevronup-icon:before {
  content: "\e90b";
  color: #ad8775;
}
.i-close-icon:before {
  content: "\e90d";
  color: #ad8775;
}
.i-commentgold-icon:before {
  content: "\e90e";
  color: #dba514;
}
.i-comment-icon:before {
  content: "\e90f";
  color: #ad8775;
}
.i-document-icon:before {
  content: "\e910";
}
.i-twitter-icon .path1:before {
  content: "\e927";
  color: rgb(45, 170, 225);
}
.i-twitter-icon .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.i-facebook-icon:before {
  content: "\e914";
  color: #1877f2;
}
.i-google-icon .path1:before {
  content: "\e915";
  color: rgb(251, 187, 0);
}
.i-google-icon .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(81, 142, 248);
}
.i-google-icon .path3:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(40, 180, 70);
}
.i-google-icon .path4:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(241, 67, 54);
}
.i-telegram-icon .path1:before {
  content: "\e923";
  color: rgb(0, 0, 0);
}
.i-telegram-icon .path2:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(200, 218, 234);
}
.i-telegram-icon .path3:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(169, 201, 221);
}
.i-telegram-icon .path4:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.i-likegold-icon:before {
  content: "\e919";
  color: #dba514;
}
.i-like-icon:before {
  content: "\e91a";
  color: #ad8775;
}
.i-minus-icon:before {
  content: "\e91b";
  color: #ad8775;
}
.i-plus-icon:before {
  content: "\e91f";
  color: #ad8775;
}
.i-downarrow-icon:before {
  content: "\e912";
  color: #ad8775;
}
.i-prevarrow-icon:before {
  content: "\e920";
  color: #ad8775;
}
.i-nextarrow-icon:before {
  content: "\e91d";
  color: #ad8775;
}
.i-nextarrow-icon-white:before {
  content: "\e91d";
  color: #ffffff;
}
.i-uparrow-icon:before {
  content: "\e929";
  color: #ad8775;
}
.i-trash-icon:before {
  content: "\e925";
  color: #ad8775;
}
.i-user-icon:before {
  content: "\e92a";
  color: #ad8775;
}
.i-smallclose-icon:before {
  content: "\e924";
  color: #ad8775;
}
.i-cancel-icon:before {
  content: "\e906";
  color: #ad8775;
}
.i-validated-icon:before {
  content: "\e92b";
  color: #ad8775;
}
.i-notvalid-icon:before {
  content: "\e91e";
  color: #f66;
}
.i-nextbtn-icon:before {
  content: "\e92f";
  color: #fff;
}
.i-prevbtn-icon:before {
  content: "\e933";
  color: #fff;
}
.i-minusbtn-icon .path1:before {
  content: "\e92c";
  color: rgb(255, 255, 255);
}
.i-minusbtn-icon .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: none;
}
.i-minusbtn-icon .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(209, 188, 178);
}
.i-plusbtn-icon .path1:before {
  content: "\e930";
  color: rgb(255, 255, 255);
}
.i-plusbtn-icon .path2:before {
  content: "\e931";
  margin-left: -1em;
  color: none;
}
.i-plusbtn-icon .path3:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(209, 188, 178);
}
.i-truck-icon:before {
  content: "\e926";
  color: #ad8775;
}
.i-truckgold-icon:before {
  content: "\e934";
  color: #dba514;
}
.i-calendargold-icon:before {
  content: "\e905";
  color: #dba514;
}
.i-emailgold-icon:before {
  content: "\e913";
  color: #dba514;
}
