@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #ede4e0;
  }

  ::-webkit-scrollbar-thumb {
    background: #d1bcb2;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ad8775;
  }

  .mega-menu::-webkit-scrollbar {
    width: 8px;
    height: 0px;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="checkbox"] {
    position: relative;
    cursor: pointer;
  }

  input[type="checkbox"]:before {
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border: 1.5px solid #d1bcb2;
    border-radius: 0;
    background-color: #fff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    transition: all 150ms ease-in-out;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -ms-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
  }
  input[type="checkbox"]:checked:before {
    background-color: #dba514;
    border: 1.5px solid #dba514;
  }

  input[type="checkbox"]:checked:after {
    content: "";
    display: block;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
    transition: all 150ms ease-in-out;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -ms-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
  }

  input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1.5px solid #d1bcb2;
    border-radius: 50%;
    outline: none;
    /* box-shadow: 0 0 5px #999;
    transition: box-shadow 0.3s ease; */
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 75%;
    height: 75%;
    margin: 12% auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked {
    border-color: #dba514;
    transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
  }

  input[type="radio"]:checked:before {
    background: #dba514;
    transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
  }

  input[type="radio"]:checked + label {
    font-weight: 500;
    color: #32324d;
    transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
  }

  input[type="password"] {
    font-family: small-caption;
    font-size: 30px;
    letter-spacing: 1px;
    color: #d1bcb2;
    vertical-align: middle;
  }
}

@layer utilities {
  .login-client,
  .basket-active {
    border-color: #dba514;
  }

  .active-tab {
    border-color: #dba514;
    color: #32324d;
  }

  .product_tab-swiper {
    display: flex !important;
  }

  .product_tab-swiper .swiper-slide {
    display: flex !important;
  }

  .selected-breadcrumb,
  .selected-sort-option {
    color: #32324d;
  }

  .selected-sort,
  .selected-page {
    color: #fff;
    border-color: #dba514;
    background-color: #dba514;
  }

  .disable-btn {
    background-color: #ede4e0;
  }

  .product_slide-selected,
  .product_filter_color-selected {
    box-shadow: 0 10px 20px 0px rgba(209, 188, 178, 0.25);
    border-color: #fff;
  }

  input#color-checkbox-white::before,
  input#color-checkbox-white:checked:before {
    background-color: #fff;
    border: 1.5px solid #ede4e0;
  }
  input#color-checkbox-white:checked:after {
    content: "";
    display: block;
    width: 6px;
    height: 11px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }

  input#color-checkbox-black::before,
  input#color-checkbox-black:checked:before {
    background-color: #000;
    border: 1.5px solid #000;
  }

  input#color-checkbox-light-blue::before,
  input#color-checkbox-light-blue:checked:before {
    background-color: #4fc3f7;
    border: 1.5px solid #4fc3f7;
  }

  input#color-checkbox-brown::before,
  input#color-checkbox-brown:checked:before {
    background-color: #795548;
    border: 1.5px solid #795548;
  }

  input#color-checkbox-red::before,
  input#color-checkbox-red:checked:before {
    background-color: #c62828;
    border: 1.5px solid #c62828;
  }

  input#color-checkbox-dark-green::before,
  input#color-checkbox-dark-green:checked:before {
    background-color: #004d40;
    border: 1.5px solid #004d40;
  }
  /* input#material-checkbox-red:checked:after {
    content: "";
    display: block;
    width: 6px;
    height: 11px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  } */

  input#color-checkbox-gray::before,
  input#color-checkbox-gray:checked:before {
    background-color: #b0bec5;
    border: 1.5px solid #b0bec5;
  }

  @media (min-width: 440px) {
    .product_tab-swiper {
      display: flex !important;
    }

    .product_tab-swiper .swiper-wrapper {
      justify-content: space-between;
      align-items: center;
    }

    .product_tab-swiper .swiper-slide {
      display: flex !important;
      width: min-content !important;
    }
  }
}

.swiper-pagination-bullet {
  background-color: #fff !important;
  border: 1px solid #d1bcb2 !important;
  opacity: 0 !important;
  width: 9px !important;
  height: 9px !important;
  visibility: hidden !important;
  transition: all 150ms ease-in-out !important;
  -webkit-transition: all 150ms ease-in-out !important;
  -moz-transition: all 150ms ease-in-out !important;
  -ms-transition: all 150ms ease-in-out !important;
  -o-transition: all 150ms ease-in-out !important;
}

.swiper-pagination-bullet-active {
  border-color: #dba514 !important;
}

.catalog_product:hover .swiper-pagination-bullet {
  opacity: 1 !important;
  visibility: visible !important;
}

.subcategories_product:hover .swiper-pagination-bullet {
  opacity: 1 !important;
  visibility: visible !important;
}

.search-result_product:hover .swiper-pagination-bullet {
  opacity: 1 !important;
  visibility: visible !important;
}

.product_img-slider_wrapper .swiper {
  overflow: visible;
}

.product_img-slider_wrapper .swiper-pagination-bullet {
  opacity: 1 !important;
  visibility: visible !important;
}

.product_img-slider_wrapper > .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -30px !important;
}

.catalog_product-swiper > .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0px !important;
}

.noUi-target {
  background: #ede4e0 !important;
}

.noUi-horizontal {
  height: 6px !important;
}

.noUi-connect {
  background: #d1bcb2 !important;
}

.noUi-horizontal .noUi-handle {
  width: 18px !important;
  height: 18px !important;
  border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -ms-border-radius: 100% !important;
  -o-border-radius: 100% !important;
  background-color: #fff !important;
  border: 2px solid #d1bcb2 !important;
  cursor: pointer !important;
}

.noUi-handle:before,
.noUi-handle:after {
  display: none !important;
}

.noUi-active {
  box-shadow: 0 0 5px #d1bcb2 !important;
  -webkit-box-shadow: 0 0 5px #d1bcb2 !important;
}

/* input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid #d1bcb2;
  border-radius: 50%;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  pointer-events: all;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid #d1bcb2;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #d1bcb2;
}

input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: 0 0 5px #d1bcb2;
  -webkit-box-shadow: 0 0 5px #d1bcb2;
  border-radius: 50%;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  width: 100%;
  position: absolute;
  background-color: #ede4e0;
  pointer-events: none;
  border-radius: 3px;
}

#fromSlider {
  height: 6px;
  background-color: transparent;
  z-index: 1;
} */
